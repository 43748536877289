import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import { AiOutlineInstagram } from "react-icons/ai";
import { FaFacebookF, FaTwitter } from "react-icons/fa";
import { GrLinkedinOption } from "react-icons/gr";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import ComingSoon from "./pages/ComingSoon";
import End from "./pages/End";
import Loader from "./loader/Loader";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";

// Lazy-loaded components
const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const Service = lazy(() => import("./pages/Service"));
const Portfolio = lazy(() => import("./pages/Portfolio"));
const Blogs = lazy(() => import("./pages/Blogs"));
const Career = lazy(() => import("./pages/Career"));
const Contact = lazy(() => import("./Components/contact/Contact"));
const Blog = lazy(() => import("./pages/Blog"));
const JobDetail = lazy(() => import("./pages/JobDetail"));
const ProjectDetail = lazy(() => import("./pages/ProjectDetail"));
const ServicePage = lazy(() => import("./pages/ServicePage"));
const JobForm = lazy(() => import("./pages/JobForm"));
const OurTeam = lazy(() => import("./pages/OurTeam"));
const TermsNcondition = lazy(() => import("./pages/TermsNcondition"));
const Policy = lazy(() => import("./pages/Policy"));

function App() {
  return (
    <>
      <BrowserRouter>
      <div style={{ position: "relative", zIndex: "10000" }}>
          <div className="bg-black header-top border-b-[1px] border-white  py-1 font-Poppins ">
            <div className=" container flex justify-between ">
              <div className="flex px-1 ">
                <a
                  className="flex items-center"
                  href="https://www.facebook.com/status2hundred?mibextid=ZbWKwL"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebookF
                    size={25}
                    className="p-[4px] mr-2  text-black  bg-white rounded-lg    "
                  />
                </a>
                <a
                  className="flex items-center"
                  href="https://instagram.com/status2hundred?igshid=YmMyMTA2M2Y="
                  target="_blank"
                  rel="noreferrer"
                >
                  <AiOutlineInstagram
                    size={25}
                    className="p-[4px] mr-2 text-black  bg-white rounded-lg    "
                  />
                </a>
                <a
                  className="flex items-center"
                  href="https://www.linkedin.com/company/status-200/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <GrLinkedinOption
                    size={25}
                    className="p-[4px] mr-2 text-black  bg-white rounded-lg    "
                  />
                </a>
                <a
                  className="flex items-center"
                  href="https://twitter.com/STATUS2001?t=yIB-Wjk8swWCU0qss_fQGQ&s=31"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaTwitter
                    size={25}
                    className="p-[4px] mr-2 text-black  bg-white rounded-lg     "
                  />
                </a>
              </div>
              <div className="flex items-center">
                <a
                  href="mailto:info@status200.net"
                  className=" no-underline text-right text-white justify-center text-[16px] flex items-end lg:justify-end "
                >
                  <MdEmail
                    size={30}
                    className="rounded pr-2 pt-2 text-white "
                    onClick={() => alert("hello")}
                  />
                  info@status200.net
                </a>
              </div>
            </div>
          </div>
        </div>
        <Navbar className="font-Poppins " />

        <div className="font-Poppins overflow-hidden text-black">
          <Suspense fallback={<div><Loader/></div>}>
            <Routes>
              <Route path={`/`} element={<Home />} />
              <Route path={`/about`} element={<About />} />
              <Route path={`/service`} element={<Service />} />
              {/* <Route path={`/portfolio`} element={<Portfolio />} />
              <Route path={`/blogs`} element={<Blogs />} />
              <Route path={`/career`} element={<Career />} /> */}
              <Route path={`/contact`} element={<Contact />} />
              <Route path={`/blog`} element={<Blog />} />
              <Route path={`/job-detail/:field`} element={<JobDetail />} />
              <Route path={`/project-detail`} element={<ProjectDetail />} />
              <Route path={`/service-detail/:type`} element={<ServicePage />} />
              <Route path={`/our-team`} element={<OurTeam />} />

              <Route path={`/portfolio`} element={<ComingSoon />} />
              <Route path={`/blogs`} element={<ComingSoon />} />
              <Route path={`/career`} element={<ComingSoon />} />

              <Route path={`*`} element={<End />} />
              <Route path={`/apply-for-job/:field`} element={<JobForm />} />
              <Route
                path={`/terms-and-condition`}
                element={<TermsNcondition />}
              />
              <Route path={`/privacy-policy`} element={<Policy />} />
            </Routes>
          </Suspense>
        </div>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
