import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import error from "../Assets/Data/404 Illustration.svg";
import AOS from 'aos';
import 'aos/dist/aos.css';

const End = () => {
    useEffect(() => {
        AOS.init({
          duration: 1000,
          easing: 'ease-in-out',
          once: true
        });
      }, []);
      return (
        <div>
    
          <div className="container flex justify-center text-black my-4 lg:my-24">
            <div className=" row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  ">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 my-auto "  data-aos="fade-right" data-aos-duration="1000" data-aos-delay="500">
                <h1 className=" font-bold text-4xl mt-6">
               Ooops!
                </h1>
                <h1 className="text-base  lg:text-3xl mt-6">
               We can't find the page</h1>
                <p className="my-4 text-sm lg:text-lg">
                The page you are looking for might have been removed or temporary unavailable
                </p>
                <p className="text-sm">Please check your Url...</p>
                <p></p>
                <Link to='/' className=" no-underline py-2 px-4 my-6 bg-hovring border-[1px] text-white  border-black bg-black rounded-md hover:bg-white hover:text-black">
                Go to home page
                </Link>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 flex justify-end"  data-aos="fade-left" data-aos-duration="1000" data-aos-delay="500">
                <img
                  className="w-[560px] h-[380px] object-contain  mt-6"
                  src={error}
                  alt=""
                />
              </div>
               </div>
          </div>
        </div>
      );
    };

export default End