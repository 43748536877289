import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'

const ComingSoon = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
            <div className='container font-Poppins text-black '>
                <div className='flex justify-center items-center flex-col my-28'>
                    <h1 className='text-[80px/120px] font-extrabold uppercase'>Coming Soon</h1>

                    <div className='w-[160px] h-[4px] bg-black rounded-full mb-3'></div>

                    <p className='m-0'>We are currently working on creating our new website.</p>
                    <p>This section will be launched soon, Something really exciting is coming.</p>
                    <Link to='/' className='bg-hovring bg-black rounded-md text-white no-underline border-[1px] border-black py-2 px-6'>Go to home page</Link>

                </div>
            </div>
        </>
    )
}

export default ComingSoon