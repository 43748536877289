
import { Link } from "react-router-dom";
import twit from "../Assets/Twit.svg";
import fb from "../Assets/fb.svg";
import insta from "../Assets/insta.svg";
import linkedIn from "../Assets/link.svg";
import logo from "../Assets/Logo Header.png";
import phone from "../Assets/phone (1).svg";
import mail from "../Assets/Email (1).svg";
import location from "../Assets/location (1).svg";
import { AiOutlineInstagram } from 'react-icons/ai'
import { FaFacebookF, FaTwitter } from 'react-icons/fa'
import { GrLinkedinOption } from 'react-icons/gr'

const Footer = () => {
  return (
    <>
      <section className="footer mt-24 font-Poppins bg-black text-white">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
              <div className="">
                <div className="">
                  <Link to="/">
                    <img src={logo} className='w-[82px] h-[100px] mt-4 object-contain' alt="" />
                  </Link>
                </div>
                <p className="pt-3 font-medium m-0">
                  At Status 200 you can digitally transform your business with
                  our top-notch solutions. We offer Software development
                  solutions to reinvent.
                </p>
              </div>

                <h1 className="font-semibold text-2xl pt-4">Follow Us On</h1>

                <div className="flex px-1 pb-4 ">
                <a href="https://www.facebook.com/status2hundred?mibextid=ZbWKwL" target='_blank' rel="noreferrer">
                  <FaFacebookF
                    size={25}
                    className="p-[4px] mr-2 text-black  bg-white rounded-lg     " />
                </a>
                <a href="https://instagram.com/status2hundred?igshid=YmMyMTA2M2Y=" target='_blank' rel="noreferrer">
                  <AiOutlineInstagram
                    size={25}
                    className="p-[4px] mr-2 text-black  bg-white rounded-lg     " />
                  </a>
                  <a href="https://www.linkedin.com/company/status-200/" target='_blank' rel="noreferrer">
                  <GrLinkedinOption
                    size={25}
                    className="p-[4px] mr-2 text-black  bg-white rounded-lg     " />
                  </a>
                      <a href="https://twitter.com/STATUS2001?t=yIB-Wjk8swWCU0qss_fQGQ&s=31" target='_blank' rel="noreferrer">
                  <FaTwitter
                    size={25}
                    className="p-[4px] mr-2 text-black  bg-white rounded-lg   " />
                  </a>

                </div>



            </div>

            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 pt-4 lg:pt-16">
              <div className="">
                <h3>Our services</h3>
                <ul className="p-0 ">
                  <li className="pb-3">
                    <Link to="/service-detail/FullStack" className="text-white font-medium no-underline ">
                      Web Development
                    </Link>
                  </li>
                  <li className="pb-3">
                    <Link to="/service-detail/Game" className="text-white font-medium  no-underline">
                      Game Development
                    </Link>
                  </li>
                  <li className="pb-3">
                    <Link to="/service-detail/MobileApp" className="text-white font-medium no-underline">
                      Mobile App Development
                    </Link>
                  </li>
                  <li className="pb-3">
                    <Link to="/service-detail/Ui-Ux" className="text-white font-medium no-underline" >
                      UI/UX Design
                    </Link>
                  </li>
                  <li className="pb-3">
                    <Link to="/service-detail/Seo" className="text-white font-medium no-underline">
                      SEO
                    </Link>
                  </li>
                  <li className="pb-3">
                    <Link to="/service-detail/Ai" className="text-white font-medium no-underline">
                      AI/ML
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 pt-4 lg:pt-16">
              <div className="">
                <h3>Quick Links</h3>
                <ul className="p-0">
                  <Link className="text-white no-underline"  to='/about'> <li className="font-medium pb-3"> About </li></Link>
                  <Link className="text-white no-underline"  to='/service'><li className="font-medium pb-3">Service</li></Link>
                  <Link className="text-white no-underline"  to='/blogs'>   <li className="font-medium pb-3">Blogs</li></Link>
                  <Link  className="text-white no-underline" to='/contact'> <li className="font-medium pb-3">Contact Us</li></Link>
                </ul>
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 pt-4 lg:pt-16">
            <div className="">
              <h3>Contact Us</h3>
              <ul className="p-0">
               <a className="text-white no-underline" href="tel:(+92)308-8888034"> <li className="font-medium flex pb-3 gap-2 pt-1 items-center"><img src={phone} className='w-4 h-4' alt="phone"/>(+92)308-8888034</li></a>
                <a className="text-white no-underline" href="mailto:info@status200.net "> <li className="font-medium flex pb-3 gap-2 pt-1 items-center"><img src={mail} className='w-4 h-4' alt="mail"/>info@status200.net </li></a>
              <a className="text-white no-underline" href="https://goo.gl/maps/2ruHt3hXze41RWhVA"> <li className="font-medium flex pb-3 gap-2 pt-1 items-baseline"><img src={location} className='w-4 h-4' alt="location"/>Office 213-B, Eden Tower, Main Boulevard, Block E 1, Gulberg III, Lahore. </li></a>

              </ul>
            </div>
          </div>


          </div>
        </div>
      </section>

      <section className="bg-black text-white border-t-[1px] border-white font-Poppins">
        <div className="container py-2">
          <div className="row flex justify-between">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 flex justify-center md:justify-start lg:justify-start">
              <div className="p-0">
                <p className="m-0 text-xs md:text-sm lg:text-base ">copyright@Status200. All rights are reserved</p>
              </div>

            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 flex justify-center md:justify-end lg:justify-end">
            <div>
            <ul className="flex justify-end gap-10 m-0 p-0 ">
            <Link className="text-white no-underline" to='/privacy-policy'>
            <li className="text-xs md:text-sm lg:text-base ">Privacy Policy </li>
            </Link>

            <Link className="text-white no-underline" to='/terms-and-condition'>
            <li className="text-xs md:text-sm lg:text-base  ">Terms And Condition </li>
            </Link>
            </ul>
            </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
