import {React, useState, useEffect} from "react";
import {AiOutlineMenu, AiOutlineClose} from "react-icons/ai";
import {Link, NavLink} from "react-router-dom";
import logo from "../Assets/Logo Header.png";

const Navbar = () => {
    const [nav, setNav] = useState(false);
    const handleClick = () => setNav(!nav);
    const handleClose = () => setNav(!nav);


    const [color, setColor] = useState('transparent');
    const [margin, setMargin] = useState('3% !important');
    const [textColor, setTextColor] = useState('white');


    const handleNav = () => {
        setNav(!nav);
    };


    // const myScroll = (event) => {
    //   console.log('scrollTop: ', event.currentTarget.scrollTop);
    //   console.log('offsetHeight: ', event.currentTarget.offsetHeight);
    // };

    // window.addEventListener('scroll', (event) => {
    //   if (window.scrollY >= 100) {
    //     console.log('scroll')
    //     document.getElementById("scrollDiv").style.marginTop = "0%";

    //    }
    //    else{
    //     document.getElementById("scrollDiv").style.marginTop = "2%";

    //    }
    // });


    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-black sticky-top font-Poppins navbar--container ">
            <div className=" container ">

                <div className="bg-transparent w-100 " style={{left: 0, top: 0}}>
                    <div className="w-100 h-[100px] bg-black">
                        <div className=" flex justify-between items-center h-full  container px-0" id="scrollDiv">
                            <Link to="/">
                                <img className="w-[68px] h-[80px] lg:w-[82px] lg:h-[100px] object-contain" src={logo}
                                     alt=""/>
                            </Link>
                            <div className="hidden md:flex">
                                <ul className="flex text-white  text-base items-center gap-4 relative">
                                    <NavLink to="/" className="no-underline ">
                                        <li className=" text-white border-b-2 border-transparent  duration-1000 hover:border-white  px-1 py-2 text-base md:text-sm lg:text-base ">
                                            Home
                                        </li>
                                    </NavLink>
                                    <NavLink to="/about" className="no-underline ">
                                        <li className="text-white border-b-2 border-transparent duration-1000  hover:border-white  px-1 py-2 text-base md:text-sm lg:text-base ">
                                            {/* <div className="dropdown relative inline-block know-btn">
                      <Link  className=" no-underline px-1 py-2 text-white ">

                      </Link>
                      <div className="dropdown-content">
                        <Link to='/about' className="duration-1000">Who we are?</Link>
                        <Link to='/our-team' className="duration-1000">Our Team</Link>
                      </div>
                    </div> */}
                                            About
                                        </li>
                                    </NavLink>
                                    <NavLink to="/service" className="no-underline ">
                                        <li className="text-white border-b-2 border-transparent  duration-1000 hover:border-white  px-1 py-2 text-base md:text-sm lg:text-base ">
                                            Service
                                        </li>
                                    </NavLink>

                                    <NavLink to="/portfolio" exact className="no-underline">
                                        {" "}
                                        <li className="text-white border-b-2 border-transparent duration-1000 hover:border-white  px-1 py-2 text-base md:text-sm lg:text-base ">
                                            Portfolio
                                        </li>
                                    </NavLink>
                                    <NavLink to="/blogs" className="no-underline ">
                                        {" "}
                                        <li className="text-white border-b-2 border-transparent duration-1000 hover:border-white  px-1 py-2 text-base md:text-sm lg:text-base ">
                                            Blogs
                                        </li>
                                    </NavLink>
                                    <NavLink to="/career" className="no-underline ">
                                        {" "}
                                        <li className="text-white border-b-2 border-transparent duration-1000 hover:border-white  px-1 py-2 text-base md:text-sm lg:text-base ">
                                            Career
                                        </li>
                                    </NavLink>
                                    <Link
                                        to="/contact"
                                        className=" bg-white1 border-white border-[1px] bg-white px-2 no-underline text-black py-2 rounded-lg text-base md:text-sm lg:text-base contact-btn  "
                                    >
                                        Contact Us
                                    </Link>
                                </ul>
                            </div>

                            {/* Hamburger menu */}

                            <div onClick={handleNav} className=" md:hidden">

                                {nav ? (
                                    <AiOutlineClose
                                        size={30}
                                        className="text-white z[1] mr-2 delay-500"
                                    />
                                ) : (
                                    <AiOutlineMenu size={30} className="text-white mr-2"/>
                                )}
                            </div>

                            {/* Mobile menu */}
                            <div
                                className={
                                    nav
                                        ? "w-full  bg-black text-white absolute h-[100vh] z-[100] top-[115px]  py-4 items-start right-[0%] flex justify-center text-center"
                                        : "absolute left-[-100%] md:hidden lg:hidden"}>
                                <ul className="md:hidden lg:hidden pl-0 w-100">
                                    <Link to="/" onClick={handleClose} className="no-underline">
                                        <li className="text-white font-medium py-2  border-b-[1px] border-white ">
                                            Home
                                        </li>
                                    </Link>
                                    <Link to='/about' onClick={handleClose} className="no-underline">
                                        <li className="text-white font-medium py-2 border-b-[1px] border-white ">
                                            About
                                        </li>
                                    </Link>
                                    <Link to="/service" onClick={handleClose} className="no-underline">
                                        <li className="text-white font-medium py-2 border-b-[1px] border-white ">
                                            Service
                                        </li>
                                    </Link>
                                    <Link to="/portfolio" onClick={handleClose} className="no-underline">
                                        <li className="text-white font-medium py-2 border-b-[1px] border-white ">
                                            Portfolio
                                        </li>
                                    </Link>
                                    <Link to="/blogs" onClick={handleClose} className="no-underline">
                                        <li className="text-white font-medium py-2 border-b-[1px] border-white ">
                                            Blogs
                                        </li>
                                    </Link>
                                    <Link to="/career" onClick={handleClose} className="no-underline">
                                        <li className="text-white font-medium py-2 border-b-[1px] border-white ">
                                            Career
                                        </li>
                                    </Link>

                                    <Link to="/contact" onClick={handleClose}
                                          className="  no-underline  my-3 w-100 flex justify-center ">
                                        <li className=" list-none bg-white bg-white1 w-[200px]  px-3 rounded-lg text-black font-medium py-2 border-[1px] border-white">
                                            Contact Us
                                        </li>
                                    </Link>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
